// eslint-disable-next-line object-curly-newline
/* eslint sort-imports: ["error", { "ignoreCase": true }] */

import { AUTH_LOGOUT } from '../store/actions/auth'

export default {
  data() {
    return {

    }
  },
  created() {
    // console.log('mixins repsonse loaded...')
  },
  methods: {
    echo() {
      console.log('mixins echo ok...')
    },
    responseError(err) {
      console.log('response =>', err.response)

      if (err.response && err.response.status === 401) {
        // zistilo sa ze request je uz neplatny, odhlas sa, ak vsak je nastaveny token_permit, poznac si ho
        // aby pri presmerovani na login stranku doslu k automatickemu prihlaseniu...
        const token_permit = localStorage.getItem('user-token_permit')
        this.$store.dispatch(AUTH_LOGOUT).then(() => {
          if (token_permit) {
            localStorage.setItem('user-token_permit', token_permit)
          }
          this.$router.push('/login')
        })

        return 'Neautorizovaný přístup!'
      }

      if (err.response && err.response.status === 500) {
        if (err.response.data && err.response.data.code && err.response.data.code === 11000) {
          return err.response.data.err || 'Duplicita!'
        }
      }

      // if (err.toString().indexOf('Network Error') > -1) {
      //   return 'Nepodařilo se navázat spojení s Velin serverem!'
      // }

      return 'Neznáma chyba!'
    },
  },
}
