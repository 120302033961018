<template>
  <div>
    <div v-if="end_content">
      <v-card
        class="amx-auto"
        style="margin-left:-128px;"
        max-width="calc(100%)"
      >
        <v-card-text>
          <div>Spoločnosť</div>
          <p class="text-h4 text--primary">
            ·· I·N·D·E·X ··· N·O·S·L·U·Š ··
          </p>
          <p>Info</p>
          <div class="text--primary">
            Váš problém bol úspešne zadaný. V dohľadnej dobe sa mu pozrieme na zúbky...<br>
            "<i>Vo vašej emailovej schránke nájdete kópiu s popisom vášho problému.</i>"
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="teal accent-4"
          >
            <a href="https://indexnoslus.sk">web</a>
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- <v-card
        style="cursor:pointer;left:0px;position:absolute;bottom:10px;"
        class="mx-auto"
        max-width="384"
        outlined
        @click="call_url('https://www.indexnoslus.cz')"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              CZE
            </div>
          </v-list-item-content>
          <img
            style="width: 278px;height: auto;"
            src="https://www.indexnoslus.cz/wp-content/uploads/2021/08/logo_svg.svg">
        </v-list-item>
      </v-card>
      <v-card
        style="cursor:pointer;right:256px;bottom:10px;position:absolute;"
        class="mx-auto"
        max-width="384"
        outlined
        @click="call_url('https://www.indexnoslus.sk')"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              SVK
            </div>
          </v-list-item-content>

          <img
            style="width: 278px;height: auto;"
            src="https://www.indexnoslus.sk/wp-content/themes/noslus/images/logo.svg?2">
        </v-list-item>
      </v-card> -->
    </div>
    <div v-else>
      <!-- SOS DIALOG -->
      <sos-form
        :show="dialog_sos"
        @completedSOS="sendSOS(...arguments)"
        @closeSOS="dialog_sos=false"
      ></sos-form>
    </div>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */

import axios from 'axios'
import response from '@/mixins/response'
import SnackBar from '@/components/snackbar/index.vue'
import sosForm from '@/components/form-sos/index.vue'

export default {
  components: {
    'snack-bar': SnackBar,
    'sos-form': sosForm,
  },
  mixins: [response],
  data() {
    return {
      // notify snack
      snackbar: false,
      snack_color: 'red',
      snack_text: '',

      dialog_sos: true,
      end_content: false,
    }
  },
  computed: {

  },
  watch: {
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize)
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.windowResize()

    this.echo()
  },
  methods: {
    snackBarMsg(p_data) {
      this.snackbar = true
      this.snack_color = p_data.snack_color
      this.snack_text = p_data.snack_text
    },
    windowResize() {
      console.log('window.innerWidth =>', window.innerWidth)
    },
    handleSOS() {
      this.dialog_sos = true
    },
    sendSOS(p_form) {
      const data = p_form
      data.login_email = this.$store.getters.getProfile.email || data.from_email
      data.login_fullname = `${this.$store.getters.getProfile.jmeno || ''} ${this.$store.getters.getProfile.prijmeni || ''}`
      data.login_fullname = data.login_fullname === ' ' ? '' : data.login_fullname.trim()
      data.login_phone = this.$store.getters.getProfile.phone
      data.login_jobtitle = this.$store.getters.getProfile.jobTitle
      data.hostname = this.hostname
      data.username = this.username
      data.type = this.type
      data.platform = this.platform
      data.localhost_alive = this.localhost_alive
      data.ipv4 = this.ipv4
      data.ipv4_mac = this.ipv4_mac
      data.wifi = this.wifi
      data.wifi_mac = this.wifi_mac
      data.device_ID = this.device_ID
      data.cpu_model = this.cpu_model
      data.cpu_count = this.cpu_count
      data.ram_total = this.ram_total
      data.ram_free = this.ram_free

      // ---

      data.k = this.$route.query.k
      const body = JSON.stringify(data)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/tool/sos/send`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.snack_text = 'Formulář byl úspěšně odeslán na IT podporu.'
          this.snack_color = 'success'
          this.snackbar = true

          this.end_content = true // zobraz obsah po odoslani formularu
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    call_url(p_url) {
      window.location.href = p_url
    },
  },
}
</script>
