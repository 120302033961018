<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar_show"
      :top="top"
      :bottom="bottom"
      :left="left"
      :right="right"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="color"
          text
          v-bind="attrs"
          @click="closeSnackBar"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable camelcase */
export default {
  props: {
    snackbar: { type: Boolean, default: false }, // ukaz/schovaj snackbar
    color: { type: String, default: 'red' }, // farba
    position: { type: String, default: 'top' }, // pozicia okna
    timeout: { type: Number, default: 5000 }, // za ako dlho sa same zavrie
    text: { type: String, default: 'Snackbar' }, // text v snackare
  },
  data() {
    return {
      snackbar_show: false,
      top: false,
      bottom: true,
      left: false,
      right: false,
    }
  },
  watch: {
    position: newVal => {
      this.handlePosition(newVal)
    },
    snackbar: {
      immediate: true,
      handler(val) {
        this.snackbar_show = val
      },
    },
  },
  created() {
    this.handlePosition(this.position)
  },
  methods: {
    handlePosition(p_pos) {
      console.log(`snackbar, handlePosition => ${p_pos}`)
      switch (p_pos) {
        case 'top':
          this.top = true
          this.bottom = false
          this.left = false
          this.right = false
          break
        case 'left':
          this.left = true
          this.bottom = false
          this.top = false
          this.right = false
          break
        case 'right':
          this.right = true
          this.bottom = false
          this.left = false
          this.top = false
          break
        default:
          this.bottom = true
          this.top = false
          this.left = false
          this.right = false
      }
    },
    closeSnackBar() {
      this.$emit('close')
    },
  },
}
</script>
